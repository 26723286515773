import React, { ComponentProps, FC, ReactNode } from 'react'

import InjectionProvider from 'ui/contexts/InjectionContext'

interface Props {
  children: ReactNode
  data: ComponentProps<typeof InjectionProvider>['data']
}

const RootLayout: FC<Props> = ({ children, data }) => (
  <InjectionProvider data={data}>{children}</InjectionProvider>
)

export default RootLayout
