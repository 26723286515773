import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'
import React, { forwardRef, useContext } from 'react'

import { PageContext } from 'ui/contexts/PageContext'

const Link = forwardRef<HTMLAnchorElement, GatsbyLinkProps<unknown>>(
  ({ to, ...props }, ref) => {
    const { locale } = useContext(PageContext)

    return (
      <GatsbyLink<unknown>
        {...props}
        ref={ref as typeof ref & string}
        to={`/${locale}${to}`}
      />
    )
  }
)

export default Link
