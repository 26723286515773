import { useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { CanvasContext } from 'ui/contexts/CanvasContext'
import { MediaContext } from 'ui/contexts/MediaContext'
import { getFontComputedStyle } from 'ui/utils'

export default () => {
  const textContainerRef = useRef<HTMLDivElement>(null)
  const media = useContext(MediaContext)
  const [textWidth, setTextWidth] = useState(0)

  const { measureText } = useContext(CanvasContext)
  const { formatMessage } = useIntl()

  const text = formatMessage({
    id: 'platforms.availability',
  })

  useEffect(() => {
    const textContainer = textContainerRef.current

    if (!textContainer) {
      return
    }

    setTextWidth(
      measureText({
        font: getFontComputedStyle(textContainer),
        text,
      })
    )
  }, [media, measureText, text])

  return {
    textContainerRef,
    textWidth,
  }
}
