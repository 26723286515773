import { useCallback, useEffect, useRef, useState } from 'react'

export default () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [context, setContext] = useState<CanvasRenderingContext2D>()

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d')

    if (context) {
      setContext(context)
    }
  }, [])

  const measureText = useCallback(
    ({
      font,
      letterSpacing = 0,
      text,
    }: {
      font: string
      letterSpacing?: number
      text: string
    }) => {
      if (!context) {
        return 0
      }

      context.font = font

      return Math.round(
        context.measureText(text).width + text.length * letterSpacing
      )
    },
    [context]
  )

  return { canvasRef, measureText }
}
