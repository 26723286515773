import classNames from 'classnames'
import React, { FC, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { showCookiePreferences } from 'ui/components/CookieConsent'
import Link from 'ui/components/Link'
import TextButton from 'ui/components/TextButton'
import { useData } from 'ui/contexts/InjectionContext'
import { PageContext } from 'ui/contexts/PageContext'
import Pages from 'ui/pages'

import styles from './styles.module.css'

interface Props {
  centeredCopyright?: boolean
  linkClassName?: string
}

const LegalNotice: FC<Props> = ({
  centeredCopyright = true,
  linkClassName,
}) => {
  const { slug } = useContext(PageContext)
  const {
    site: {
      siteMetadata: { copyright },
    },
  } = useData()

  return (
    <div className={styles.container}>
      <TextButton className={linkClassName} onClick={showCookiePreferences}>
        <FormattedMessage id="legal_notice.cookie_preferences" />
      </TextButton>

      <Link
        className={classNames(
          styles.link,
          {
            [styles.currentPage]: slug === Pages.PRIVACY_POLICY,
          },
          linkClassName
        )}
        to={Pages.PRIVACY_POLICY}
      >
        <FormattedMessage id="legal_notice.privacy_policy" />
      </Link>

      <Link
        className={classNames(
          styles.link,
          {
            [styles.currentPage]: slug === Pages.TERMS_AND_CONDITIONS,
          },
          linkClassName
        )}
        to={Pages.TERMS_AND_CONDITIONS}
      >
        <FormattedMessage id="legal_notice.terms_and_conditions" />
      </Link>

      <div
        className={classNames(styles.copyright, {
          [styles.centered]: centeredCopyright,
        })}
      >
        © {new Date().getFullYear()} {copyright}
      </div>
    </div>
  )
}

export default LegalNotice
