import classNames from 'classnames'
import FocusTrap from 'focus-trap-react'
import React, { FC, ReactNode, useContext } from 'react'

import Burger from 'ui/components/Burger'
import { Theme } from 'ui/components/Section'
import { Media, MediaContext } from 'ui/contexts/MediaContext'
import { PageContext } from 'ui/contexts/PageContext'

import useState, { State } from './state'
import styles from './styles.module.css'

export { State }
export const ANIMATED_ELEMENTS_STYLES = styles.animated

interface Props {
  anchoredClassName?: string
  fillRatio?: number
  freeClassName?: string
  getElementsWidth?: () => number
  getTheme: (slug: string, media: Media) => Theme
  left?: (state: State, burgerized: boolean) => ReactNode
  right?: (state: State, burgerized: boolean) => ReactNode
}

const Navbar: FC<Props> = ({
  anchoredClassName,
  fillRatio = 1,
  freeClassName,
  getElementsWidth,
  getTheme,
  left,
  right,
}) => {
  const media = useContext(MediaContext)
  const { slug } = useContext(PageContext)
  const { burgerized, fullscreenRef, navbarRef, state, toggleFullscreen } =
    useState(fillRatio, getElementsWidth)

  const theme = state === State.ANCHORED ? getTheme(slug, media) : Theme.DARK

  return (
    <FocusTrap active={state === State.FULLSCREEN}>
      <div
        className={classNames(
          {
            'fika-burgerized': burgerized,
          },
          `fika-${theme}-theme`
        )}
      >
        <div
          ref={navbarRef}
          className={classNames({
            [classNames(styles.anchoredNavbar, anchoredClassName)]:
              state === State.ANCHORED ||
              state === State.FULLSCREEN ||
              state === State.FULLSCREEN_CLOSING,
            [classNames(styles.freeNavbar, freeClassName)]:
              state === State.FREE,
          })}
        >
          {left?.(state, burgerized)}

          <div className={styles.separator} />

          {burgerized ? (
            <Burger
              active={state === State.FULLSCREEN}
              lineHeight={4}
              lineSpacing={6}
              width={30}
              onClick={toggleFullscreen}
            />
          ) : (
            right?.(state, burgerized)
          )}
        </div>

        {burgerized && (
          <div
            ref={fullscreenRef}
            className={classNames(styles.fullscreen, {
              [styles.closed]: state !== State.FULLSCREEN,
            })}
          >
            {right?.(state, burgerized)}
          </div>
        )}
      </div>
    </FocusTrap>
  )
}

export default Navbar
