import React, { ComponentProps, forwardRef, useMemo } from 'react'

import ExternalLinks from 'ui/components/ExternalLinks'
import { useData } from 'ui/contexts/InjectionContext'

export type TSocialMedia =
  | 'Discord'
  | 'Facebook'
  | 'GitHub'
  | 'Instagram'
  | 'LinkedIn'
  | 'X'

interface Props {
  links: {
    ariaLabel: TSocialMedia
    to: string
  }[]
}

const SocialMedia = forwardRef<
  HTMLAnchorElement,
  Omit<ComponentProps<typeof ExternalLinks>, 'links'> & Props
>(({ links, ...props }, ref) => {
  const { discord, facebook, github, instagram, linkedin, x } = useData()

  const discordIcon = discord?.publicURL
  const facebookIcon = facebook?.publicURL
  const githubIcon = github?.publicURL
  const instagramIcon = instagram?.publicURL
  const linkedinIcon = linkedin?.publicURL
  const xIcon = x?.publicURL

  const mappedLinks = useMemo(
    () =>
      links.map(link => {
        let src

        switch (link.ariaLabel) {
          case 'Discord':
            src = discordIcon
            break
          case 'Facebook':
            src = facebookIcon
            break
          case 'GitHub':
            src = githubIcon
            break
          case 'Instagram':
            src = instagramIcon
            break
          case 'LinkedIn':
            src = linkedinIcon
            break
          case 'X':
            src = xIcon
            break
          default:
            throw new Error('Unsupported social media type!')
        }

        return { ...link, src }
      }),
    [
      discordIcon,
      facebookIcon,
      githubIcon,
      instagramIcon,
      linkedinIcon,
      links,
      xIcon,
    ]
  )

  return <ExternalLinks ref={ref} links={mappedLinks} {...props} />
})

export default SocialMedia
