import classNames from 'classnames'
import React, { FC, HTMLInputTypeAttribute } from 'react'

import useState from './state'
import styles from './styles.module.css'

interface Props {
  className?: string
  hollow?: boolean
  name: string
  onChange: (value: string) => void
  placeholder?: string
  required?: boolean
  type?: HTMLInputTypeAttribute
  validate?: (value: string) => boolean
  value: string
}

const Input: FC<Props> = ({
  className,
  hollow = false,
  name,
  onChange,
  placeholder,
  required,
  type,
  validate,
  value,
}) => {
  const { inputRef, isValid, setValidity } = useState()

  return (
    <input
      ref={inputRef}
      className={classNames(
        styles.input,
        {
          [styles.hollow]: hollow,
          [styles.invalid]: !isValid,
        },
        className
      )}
      id={name}
      name={name}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
      onBlur={() => {
        if (!value) {
          setValidity(true)
          return
        }

        if (validate) {
          setValidity(validate(value))
        }
      }}
      onChange={event => onChange(event.target.value)}
      onKeyUp={event => {
        if (event.key === 'Enter') {
          inputRef.current?.blur()
        }
      }}
    />
  )
}

export default Input
