import React, { createContext, FC, ReactNode, useMemo } from 'react'

import useState from './state'

export const CanvasContext = createContext({
  measureText: (() => 0) as ({
    font,
    letterSpacing,
    text,
  }: {
    font: string
    letterSpacing?: number
    text: string
  }) => number,
})

interface Props {
  children: ReactNode
}

const CanvasProvider: FC<Props> = ({ children }) => {
  const { canvasRef, measureText } = useState()

  return (
    <CanvasContext.Provider
      value={useMemo(() => ({ measureText }), [measureText])}
    >
      {children}

      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </CanvasContext.Provider>
  )
}

export default CanvasProvider
