import classNames from 'classnames'
import React, { forwardRef, ReactNode } from 'react'
import { ReactSVG } from 'react-svg'

import { useData } from 'ui/contexts/InjectionContext'

import styles from './styles.module.css'

export enum Orientation {
  DOWNWARD,
  UPWARD,
}

interface Props {
  children?: ReactNode
  className?: string
  orientation?: Orientation
}

const Flourish = forwardRef<HTMLDivElement, Props>(
  ({ children, className, orientation = Orientation.DOWNWARD }, ref) => {
    const {
      flourish: { publicURL: flourish },
    } = useData()

    return (
      <div
        ref={ref}
        className={classNames(
          styles.container,
          {
            [styles.flipped]: orientation === Orientation.UPWARD,
          },
          className
        )}
      >
        <ReactSVG className={styles.flourish} src={flourish} />
        <div className={styles.content}>{children}</div>
      </div>
    )
  }
)

export default Flourish
