import type { TPageContext } from '@shared/ui'
import type { GatsbyBrowser } from 'gatsby'
import React from 'react'

import PageLayout from 'fika/components/PageLayout'
import RootLayout from 'fika/components/RootLayout'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props: { pageContext },
}) => (
  <PageLayout pageContext={pageContext as TPageContext}>{element}</PageLayout>
)

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <RootLayout>{element}</RootLayout>
