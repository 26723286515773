import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { ReactSVG } from 'react-svg'

import styles from './styles.module.css'

interface Props {
  ariaLabel?: string
  ariaLabelledBy?: string
  className?: string
  focusable?: boolean
  imageRef?: ReactSVG['props']['afterInjection']
  src: string
  to: string
}

const IconLink = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      ariaLabel,
      ariaLabelledBy,
      className,
      focusable = true,
      src,
      imageRef,
      to,
    },
    ref
  ) => (
    <a
      key={to}
      ref={ref}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      className={classNames(styles.icon, className)}
      href={to}
      rel="noreferrer"
      tabIndex={focusable ? undefined : -1}
      target="_blank"
    >
      <ReactSVG afterInjection={imageRef} src={src} />
    </a>
  )
)

export default IconLink
