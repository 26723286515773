import classNames from 'classnames'
import React, { ComponentProps, FC } from 'react'

import { BaseButton } from 'ui/components/Button'

import styles from './styles.module.css'

const TextButton: FC<ComponentProps<typeof BaseButton>> = ({
  className,
  ...props
}) => (
  <BaseButton {...props} className={classNames(styles.container, className)} />
)

export default TextButton
