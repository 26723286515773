import 'fika/global-styles.css'
import 'aos/dist/aos.css'
import 'react-tooltip/dist/react-tooltip.css'

import { type TPageContext, PageLayout as SharedPageLayout } from '@shared/ui'
import AOS from 'aos'
import React, { FC, ReactNode, useEffect } from 'react'

import TRANSLATIONS from 'fika/translations'

import Contact from './Contact'
import Navbar from './Navbar'

interface Props {
  children: ReactNode
  pageContext: TPageContext
}

const PageLayout: FC<Props> = ({ children, pageContext }) => {
  useEffect(() => {
    AOS.init({
      disable: () => false,
      duration: 700,
      offset: 120,
    })
  }, [])

  return (
    <SharedPageLayout pageContext={pageContext} translations={TRANSLATIONS}>
      <Navbar />
      {children}
      <Contact />
    </SharedPageLayout>
  )
}

export default PageLayout
