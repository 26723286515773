import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query Navbar {
    logo: file(relativePath: { eq: "fika-productions.svg" }) {
      publicURL
    }

    logoMobile: file(relativePath: { eq: "fika.svg" }) {
      publicURL
    }
  }
`

export default () =>
  useStaticQuery<RecursiveNonNullable<Fika.Queries.NavbarQuery>>(query)
