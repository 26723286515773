import { useRef, useState } from 'react'

export default () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isValid, setValidity] = useState(true)

  return {
    inputRef,
    isValid,
    setValidity,
  }
}
