import 'swiper/css'
import 'swiper/css/autoplay'

import React, {
  Children,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

import { useRemToPx } from 'ui/utils'

import Navigation from './Navigation'
import styles from './styles.module.css'

interface Props {
  children: ReactNode
  getCaption?: (slide: Element) => string
}

const Carousel: FC<Props> = ({ children, getCaption }) => {
  const swiperRef = useRef<SwiperRef>(null)
  const [caption, setCaption] = useState<string>()

  useEffect(() => {
    if (!getCaption) {
      return
    }

    const swiper = swiperRef.current?.swiper

    if (swiper) {
      setCaption(getCaption(swiper.slides[swiper.activeIndex]))
    }
  }, [getCaption])

  return (
    <Swiper
      ref={swiperRef}
      autoplay={{ delay: 10000, disableOnInteraction: false }}
      centeredSlides
      grabCursor
      loop
      loopedSlides={Children.count(children)}
      modules={[Autoplay]}
      simulateTouch
      slideToClickedSlide
      slidesPerView="auto"
      spaceBetween={useRemToPx(1)}
      speed={500}
      onSlideChange={({ activeIndex, slides }) => {
        if (getCaption) {
          setCaption(getCaption(slides[activeIndex]))
        }
      }}
    >
      {Children.map(children, child => (
        <SwiperSlide className={styles.slide}>{child}</SwiperSlide>
      ))}

      <div className={styles.bottomContainer}>
        <div className={getCaption ? styles.caption : styles.noCaption}>
          {caption}
        </div>

        <Navigation className={styles.navigation} />
      </div>
    </Swiper>
  )
}

export default Carousel
