import classNames from 'classnames'
import React, { createContext, FC, ReactNode, useMemo } from 'react'

import useState from './state'
import styles from './styles.module.css'

export const LoadingContext = createContext([() => {}, () => {}, () => {}])

interface Props {
  children: ReactNode
  loader?: () => ReactNode
}

const LoadingProvider: FC<Props> = ({ children, loader }) => {
  const {
    loadingScreenRef,
    notify,
    register,
    skip,
    isLoading,
    isTransitioning,
  } = useState()

  return (
    <>
      <div
        ref={loadingScreenRef}
        className={classNames(styles.loading, {
          [styles.loaded]: !isLoading,
          [styles.ended]: isTransitioning === false,
        })}
      >
        {loader?.()}
      </div>

      <LoadingContext.Provider
        value={useMemo(
          () => [register, notify, skip],
          [register, notify, skip]
        )}
      >
        {children}
      </LoadingContext.Provider>
    </>
  )
}

export default LoadingProvider
