import classNames from 'classnames'
import React, { forwardRef, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import Link from 'ui/components/Link'
import { PageContext } from 'ui/contexts/PageContext'
import { Anchor } from 'ui/utils'

import styles from './styles.module.css'

interface Props {
  className?: string
  focusable?: boolean
  links: { isStatic: boolean; to: string; translationId: string }[]
}

const PageLinks = forwardRef<HTMLAnchorElement, Props>(
  ({ className, focusable = true, links }, ref) => {
    const { locale, slug } = useContext(PageContext)

    return (
      <>
        {links.map(({ isStatic, to, translationId }, index) => {
          const EffectiveLink = isStatic ? Anchor : Link

          return (
            <EffectiveLink
              key={to}
              ref={index === 0 ? ref : undefined}
              aria-labelledby={translationId}
              className={classNames(
                styles.pageLink,
                {
                  [styles.currentPage]: slug === to,
                },
                className
              )}
              href={`/${locale}${to}`}
              tabIndex={focusable ? undefined : -1}
              to={to}
            >
              <FormattedMessage id={translationId} />
            </EffectiveLink>
          )
        })}
      </>
    )
  }
)

export default PageLinks
