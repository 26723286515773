import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query SocialMediaLinks {
    site {
      siteMetadata {
        externalUrls {
          facebook
          github
          instagram
          linkedin
          x
        }
      }
    }
  }
`

export default () =>
  useStaticQuery<RecursiveNonNullable<Fika.Queries.SocialMediaLinksQuery>>(
    query
  )
