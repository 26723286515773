import classNames from 'classnames'
import React, {
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
  ReactNode,
} from 'react'

import styles from './styles.module.css'

interface Props {
  children?: ReactNode
  className?: string
  disabled?: boolean
  focusable?: boolean
  id?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export const BaseButton: FC<Props> = ({
  children,
  className,
  disabled = false,
  focusable = true,
  id,
  onClick,
  type = 'button',
}) => (
  <button
    className={className}
    disabled={disabled}
    id={id}
    tabIndex={focusable ? undefined : -1}
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
)

export enum Transparency {
  REAL,
  FAKE,
}

interface ExtendedProps extends Props {
  hollow?: boolean
  transparency?: Transparency
}

const Button: FC<ExtendedProps> = ({
  className,
  hollow = false,
  transparency = Transparency.REAL,
  ...props
}) => (
  <BaseButton
    {...props}
    className={classNames(
      styles.container,
      {
        [styles.hollow]: hollow,
        [styles.fakeTransparency]: transparency === Transparency.FAKE,
      },
      className
    )}
  />
)

export default Button
