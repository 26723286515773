import React, { ComponentProps, FC, ReactNode } from 'react'

import CookieConsent from 'ui/components/CookieConsent'
import CanvasProvider from 'ui/contexts/CanvasContext'
import InternationalizationProvider from 'ui/contexts/InternationalizationContext'
import LoadingProvider from 'ui/contexts/LoadingContext'
import MediaProvider from 'ui/contexts/MediaContext'
import PageProvider, { type TPageContext } from 'ui/contexts/PageContext'

interface Props {
  children: ReactNode
  loader?: () => ReactNode
  pageContext: TPageContext
  translations: ComponentProps<
    typeof InternationalizationProvider
  >['translations']
}

const PageLayout: FC<Props> = ({
  children,
  loader,
  pageContext,
  translations,
}) => (
  <PageProvider value={pageContext}>
    <InternationalizationProvider translations={translations}>
      <MediaProvider>
        <CanvasProvider>
          <LoadingProvider loader={loader}>
            {children}
            <CookieConsent />
          </LoadingProvider>
        </CanvasProvider>
      </MediaProvider>
    </InternationalizationProvider>
  </PageProvider>
)

export default PageLayout
