import classNames from 'classnames'
import React, { forwardRef, MouseEventHandler } from 'react'
import { ReactSVG } from 'react-svg'

import styles from './styles.module.css'

interface Props {
  ariaLabel?: string
  ariaLabelledBy?: string
  className?: string
  focusable?: boolean
  id?: string
  imageRef?: ReactSVG['props']['afterInjection']
  onClick?: MouseEventHandler<HTMLButtonElement>
  src: string
}

const Fab = forwardRef<HTMLButtonElement, Props>(
  (
    {
      ariaLabel,
      ariaLabelledBy,
      className,
      focusable = true,
      id,
      imageRef,
      onClick,
      src,
    },
    ref
  ) => (
    <button
      ref={ref}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      className={classNames(styles.container, className)}
      id={id}
      tabIndex={focusable ? undefined : -1}
      type="button"
      onClick={onClick}
    >
      <ReactSVG afterInjection={imageRef} className={styles.image} src={src} />
    </button>
  )
)

export default Fab
