import {
  ExternalLinkType,
  LanguageSelector,
  Link,
  Navbar as SharedNavbar,
  NAVBAR_ANIMATED_ELEMENT_STYLES,
  NavbarState,
  PageLinks,
  SocialMedia,
} from '@shared/ui'
import classNames from 'classnames'
import React, { FC, ReactNode, useCallback } from 'react'
import { ReactSVG } from 'react-svg'

import useSocialMediaLinks from 'fika/hooks/SocialMediaLinks'
import { getTheme, NAVBAR_LINKS } from 'fika/pages'

import useData from './query'
import useState from './state'
import styles from './styles.module.css'

const Navbar: FC = () => {
  const socialMediaLinks = useSocialMediaLinks()
  const {
    getHomeLinkWidth,
    getLanguageSelectorWidth,
    getPageLinksWidth,
    homeLinkImageRef,
    homeLinkRef,
    languageSelectorRef,
    pageLinkRef,
  } = useState()

  const {
    logo: { publicURL: logo },
    logoMobile: { publicURL: logoMobile },
  } = useData()

  return (
    <SharedNavbar
      getElementsWidth={useCallback(
        () =>
          getHomeLinkWidth() + getPageLinksWidth() + getLanguageSelectorWidth(),
        [getHomeLinkWidth, getPageLinksWidth, getLanguageSelectorWidth]
      )}
      getTheme={getTheme}
      left={useCallback<(state: NavbarState, burgerized: boolean) => ReactNode>(
        (_, burgerized) => (
          <Link
            ref={homeLinkRef}
            className={classNames(styles.link, styles.homeLink)}
            to="/"
          >
            <ReactSVG
              afterInjection={homeLinkImageRef}
              className={styles.homeLinkImage}
              src={burgerized ? logoMobile : logo}
            />
          </Link>
        ),
        [homeLinkImageRef, homeLinkRef, logo, logoMobile]
      )}
      right={useCallback<
        (state: NavbarState, burgerized: boolean) => ReactNode
      >(
        (state, burgerized) => (
          <>
            <PageLinks
              ref={burgerized ? undefined : pageLinkRef}
              className={classNames(
                styles.link,
                NAVBAR_ANIMATED_ELEMENT_STYLES
              )}
              focusable={
                burgerized ? state === NavbarState.FULLSCREEN : undefined
              }
              links={NAVBAR_LINKS}
            />

            <LanguageSelector
              ref={burgerized ? undefined : languageSelectorRef}
              className={styles.languageSelector}
              containerClassName={classNames(
                styles.link,
                NAVBAR_ANIMATED_ELEMENT_STYLES
              )}
              focusable={
                burgerized ? state === NavbarState.FULLSCREEN : undefined
              }
            />

            {burgerized && (
              <div className={styles.socialMediaLinks}>
                <SocialMedia
                  className={styles.socialMediaLink}
                  focusable={state === NavbarState.FULLSCREEN}
                  links={socialMediaLinks}
                  type={ExternalLinkType.FABS}
                />
              </div>
            )}
          </>
        ),
        [languageSelectorRef, pageLinkRef, socialMediaLinks]
      )}
    />
  )
}

export default Navbar
