import React, { FC } from 'react'

import { useData } from 'ui/contexts/InjectionContext'
import { Locale } from 'ui/contexts/InternationalizationContext'

interface Props {
  locale: Locale
}

const SEO: FC<Props> = ({ locale }) => {
  const {
    site: {
      siteMetadata: { baseUrl, descriptions, keywords, titles },
    },
    ogImage: {
      childImageSharp: {
        original: { height: ogImageHeight, width: ogImageWidth },
      },
      publicURL: ogImage,
    },
  } = useData()

  return (
    <>
      <html lang={locale} />
      <title>{titles[locale]}</title>
      <meta content={titles[locale]} property="og:title" />
      <meta content={descriptions[locale]} property="description" />
      <meta content={descriptions[locale]} property="og:description" />
      <meta content="website" property="og:type" />
      <meta content={`${baseUrl}/${locale}`} property="og:url" />
      <meta content={keywords[locale]} property="keywords" />
      <meta content={baseUrl + ogImage} property="og:image" />
      <meta content={baseUrl + ogImage} property="og:image:url" />
      <meta content="image/png" property="og:image:type" />
      <meta content={ogImageWidth.toString()} property="og:image:width" />
      <meta content={ogImageHeight.toString()} property="og:image:height" />
    </>
  )
}

export default SEO
