import classNames from 'classnames'
import { Link } from 'gatsby'
import React, { forwardRef, useContext } from 'react'

import { useData } from 'ui/contexts/InjectionContext'
import { PageContext } from 'ui/contexts/PageContext'

import styles from './styles.module.css'

interface Props {
  className?: string
  containerClassName?: string
  focusable?: boolean
  hollow?: boolean
}

const LanguageSelector = forwardRef<HTMLAnchorElement, Props>(
  (
    { className, containerClassName, focusable = true, hollow = false },
    ref
  ) => {
    const { locale: activeLocale, slug } = useContext(PageContext)
    const {
      site: {
        siteMetadata: { locales },
      },
    } = useData()

    const nextLocale =
      locales[(locales.indexOf(activeLocale) + 1) % locales.length]

    return (
      <Link
        ref={ref as typeof ref & string}
        className={classNames(styles.container, containerClassName)}
        tabIndex={-1}
        to={`/${nextLocale}${slug}`}
      >
        <button
          className={classNames(
            styles.languageSelector,
            { [styles.hollow]: hollow },
            className
          )}
          tabIndex={focusable ? undefined : -1}
          type="button"
        >
          {nextLocale}
        </button>
      </Link>
    )
  }
)

export default LanguageSelector
