import { useEffect, useState } from 'react'

export enum Media {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
}

function isMobile() {
  if (window.matchMedia('(orientation: landscape)').matches) {
    return window.innerWidth <= 823 || window.innerHeight <= 575
  }

  if (window.matchMedia('(orientation: portrait)').matches) {
    return window.innerWidth <= 767
  }

  return false
}

function isTablet() {
  if (window.matchMedia('(orientation: landscape)').matches) {
    return window.innerWidth > 823 && window.innerWidth <= 1024
  }

  if (window.matchMedia('(orientation: portrait)').matches) {
    return window.innerWidth > 767 && window.innerWidth <= 1024
  }

  return false
}

export default () => {
  const [media, setMediaState] = useState(Media.DESKTOP)

  useEffect(() => {
    function updateMedia() {
      let effectiveMedia = Media.DESKTOP

      if (isMobile()) {
        effectiveMedia = Media.MOBILE
      } else if (isTablet()) {
        effectiveMedia = Media.TABLET
      }

      if (media !== effectiveMedia) {
        setMediaState(effectiveMedia)
      }

      document.documentElement.style.setProperty(
        '--fika-fullscreen-height',
        `${document.documentElement.clientHeight}px`
      )

      document.documentElement.style.setProperty(
        '--fika-fullscreen-width',
        `${document.documentElement.clientWidth}px`
      )
    }

    updateMedia()
    window.addEventListener('resize', updateMedia)

    return () => window.removeEventListener('resize', updateMedia)
  }, [media])

  return media
}
