import classNames from 'classnames'
import React, { FC, ReactNode, useCallback, useRef, useState } from 'react'
import Collapsible from 'react-collapsible'
import { FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'

import Fab from 'ui/components/Fab'
import { useData, useOptionalDependency } from 'ui/contexts/InjectionContext'

import styles from './styles.module.css'

interface Props {
  children?: ReactNode
  contentClassName?: string
}

const CollapsibleSection: FC<Props> = ({ children, contentClassName }) => {
  const aos = useOptionalDependency('aos')
  const containerRef = useRef<HTMLDivElement>(null)
  const [opened, open] = useState(false)

  const {
    collapsibleSectionIcon: { publicURL: collapsibleSectionIcon },
    flourish: { publicURL: flourish },
  } = useData()

  const getChildren = useCallback(
    () =>
      Array.from(
        containerRef.current?.getElementsByClassName(styles.content)[0]
          ?.children ?? []
      ),
    []
  )

  const disableAos = useCallback(
    () =>
      getChildren().forEach(child => child.classList.add(styles.disableAos)),
    [getChildren]
  )

  const enableAos = useCallback(() => {
    getChildren().forEach(child => child.classList.remove(styles.disableAos))
    aos?.refresh()
  }, [aos, getChildren])

  return (
    <Collapsible
      className={styles.collapsible}
      containerElementProps={{ ref: containerRef }}
      contentInnerClassName={classNames(styles.content, contentClassName)}
      easing="ease"
      handleTriggerClick={() => null}
      open={opened}
      openedClassName={styles.collapsible}
      trigger={
        <div className={styles.flourishContainer}>
          <ReactSVG className={styles.flourish} src={flourish} />

          <div className={styles.text} id="collapsible_section.action">
            <FormattedMessage
              id={`collapsible_section.${opened ? 'less' : 'more'}`}
            />
          </div>

          <Fab
            ariaLabelledBy="collapsible_section.action"
            className={classNames(
              styles.button,
              opened ? styles.collapse : styles.expand
            )}
            src={collapsibleSectionIcon}
            onClick={useCallback(() => open(!opened), [opened])}
          />
        </div>
      }
      triggerClassName={styles.trigger}
      triggerOpenedClassName={styles.trigger}
      triggerTagName="div"
      onClose={enableAos}
      onClosing={disableAos}
      onOpen={enableAos}
      onOpening={disableAos}
    >
      {children}
    </Collapsible>
  )
}

export default CollapsibleSection
