import { createContext } from 'react'

import type { Locale } from 'ui/contexts/InternationalizationContext'

export interface TPageContext extends Record<string, unknown> {
  locale: Locale
  slug: string
}

export const PageContext = createContext<TPageContext>({
  locale: 'en' as Locale,
  slug: '/',
})

export default PageContext.Provider
