import { CanvasContext, getFontComputedStyle, pxValue } from '@shared/ui'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'

import { NAVBAR_LINKS } from 'fika/pages'

export default () => {
  const homeLinkRef = useRef<HTMLAnchorElement>(null)

  const [homeLinkImageWidth, setHomeLinkImageWidth] = useState(0)
  const homeLinkImageRef = useCallback<
    NonNullable<ReactSVG['props']['afterInjection']>
  >(
    (_, element) => {
      if (element && homeLinkImageWidth === 0) {
        setHomeLinkImageWidth(element.clientWidth)
      }
    },
    [homeLinkImageWidth]
  )

  const getHomeLinkWidth = useCallback(() => {
    const homeLink = homeLinkRef.current

    if (!homeLink) {
      return 0
    }

    return homeLinkImageWidth + pxValue(getComputedStyle(homeLink).paddingRight)
  }, [homeLinkImageWidth])

  const pageLinkMeasurements = useRef({
    font: '',
    letterSpacing: 0,
    marginRight: 0,
  })
  const pageLinkRef = useRef<HTMLAnchorElement>(null)
  const { measureText } = useContext(CanvasContext)
  const { formatMessage } = useIntl()

  const pageLinks = useMemo(
    () =>
      NAVBAR_LINKS.map(({ translationId }) =>
        formatMessage({ id: translationId }).toUpperCase()
      ),
    [formatMessage]
  )

  const getPageLinksWidth = useCallback(() => {
    const pageLink = pageLinkRef.current

    if (pageLink) {
      pageLinkMeasurements.current = {
        font: getFontComputedStyle(pageLink),
        letterSpacing: pxValue(getComputedStyle(pageLink).letterSpacing),
        marginRight: pxValue(getComputedStyle(pageLink).marginRight),
      }
    }

    const pageLinksWidth = pageLinks.reduce(
      (accumulator, current) =>
        accumulator +
        measureText({
          font: pageLinkMeasurements.current.font,
          letterSpacing: pageLinkMeasurements.current.letterSpacing,
          text: current,
        }),
      0
    )

    return (
      pageLinksWidth +
      pageLinkMeasurements.current.marginRight * pageLinks.length
    )
  }, [measureText, pageLinks])

  const languageSelectorMeasurements = useRef({ width: 0 })
  const languageSelectorRef = useRef<HTMLAnchorElement>(null)

  const getLanguageSelectorWidth = useCallback(() => {
    const languageSelector = languageSelectorRef.current

    if (languageSelector) {
      languageSelectorMeasurements.current = {
        width: languageSelector.clientWidth,
      }
    }

    return languageSelectorMeasurements.current.width
  }, [])

  return {
    getHomeLinkWidth,
    getLanguageSelectorWidth,
    getPageLinksWidth,
    homeLinkImageRef,
    homeLinkRef,
    languageSelectorRef,
    pageLinkRef,
  }
}
