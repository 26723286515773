import React, { forwardRef } from 'react'
import { ReactSVG } from 'react-svg'

import Fab from 'ui/components/Fab'
import IconLink from 'ui/components/IconLink'

export enum Type {
  ICONS,
  FABS,
}

interface Props {
  className?: string
  focusable?: boolean
  imageRef?: ReactSVG['props']['afterInjection']
  links: { ariaLabel: string; src: string; to: string }[]
  type: Type
}

const ExternalLinks = forwardRef<HTMLAnchorElement, Props>(
  ({ className, focusable = true, links, imageRef, type }, ref) => (
    <>
      {links.map(({ ariaLabel, src, to }, index) => {
        switch (type) {
          case Type.FABS:
            return (
              <a
                key={to}
                ref={index === 0 ? ref : undefined}
                aria-label={ariaLabel}
                className={className}
                href={to}
                rel="noreferrer"
                tabIndex={-1}
                target="_blank"
              >
                <Fab
                  ariaLabel={ariaLabel}
                  focusable={focusable}
                  imageRef={index === 0 ? imageRef : undefined}
                  src={src}
                />
              </a>
            )
          case Type.ICONS:
            return (
              <IconLink
                key={to}
                ref={index === 0 ? ref : undefined}
                ariaLabel={ariaLabel}
                className={className}
                focusable={focusable}
                imageRef={index === 0 ? imageRef : undefined}
                src={src}
                to={to}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
)

export default ExternalLinks
