import {
  ExternalLinkType,
  LegalNotice,
  PageContext,
  sanitizeText,
  Section,
  SocialMedia,
  Theme,
} from '@shared/ui'
import classNames from 'classnames'
import React, { FC, useContext, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'

import useSocialMediaLinks from 'fika/hooks/SocialMediaLinks'
import Pages from 'fika/pages'

import useData from './query'
import styles from './styles.module.css'

const Contact: FC = () => {
  const { locale } = useContext(PageContext)

  const socialMediaLinks = useSocialMediaLinks()
  const {
    email: { publicURL: email },
    marker: { publicURL: marker },
    site: {
      siteMetadata: {
        emails: { hiring: hiringEmail, main: mainEmail },
      },
    },
  } = useData()

  const emails = useMemo(
    () => [mainEmail, hiringEmail],
    [mainEmail, hiringEmail]
  )

  return (
    <Section className={styles.shortEnd} theme={Theme.DARK}>
      <div className={styles.container}>
        <div className={styles.title}>
          <FormattedMessage id="contact.title" />
        </div>

        <div className={styles.item}>
          <ReactSVG className={styles.icon} src={email} />

          <div className={styles.linkContainer}>
            {emails.map(email => (
              <a key={email} className={styles.link} href={`mailto:${email}`}>
                {email}
              </a>
            ))}
          </div>
        </div>

        <div className={styles.item}>
          <ReactSVG className={styles.icon} src={marker} />

          <FormattedMessage id="contact.location">
            {sanitizeText({ lines: true })}
          </FormattedMessage>
        </div>

        <div className={classNames(styles.item, styles.noIcon)}>
          <a
            aria-labelledby="contact.press_kit"
            className={styles.link}
            href={`/${locale}${Pages.PRESS_KIT}`}
            id="contact.press_kit"
          >
            <FormattedMessage id="contact.press_kit" />
          </a>
        </div>

        <div className={styles.socialMediaLinks}>
          <SocialMedia
            className={styles.socialMediaLink}
            links={socialMediaLinks}
            type={ExternalLinkType.FABS}
          />
        </div>

        <div className={styles.endOfPage}>
          <div>
            <LegalNotice
              centeredCopyright={false}
              linkClassName={styles.interactable}
            />

            <div className={styles.greeting}>
              <FormattedMessage id="contact.greeting" />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Contact
