import classNames from 'classnames'
import React, { CSSProperties, FC, MouseEventHandler } from 'react'

import styles from './styles.module.css'

interface Props {
  active: boolean
  lineHeight: number
  lineSpacing: number
  onClick?: MouseEventHandler<HTMLButtonElement>
  width: number
}

const Burger: FC<Props> = ({
  active,
  lineHeight,
  lineSpacing,
  onClick,
  width,
}) => {
  const height = 2 * lineSpacing + 3 * lineHeight

  return (
    <button
      aria-label="Menu"
      id={
        styles.container /* Higher specificity than navbar button theme overrides */
      }
      style={{ height: `${height}px`, width: `${width}px` }}
      type="button"
      onClick={onClick}
    >
      <div
        className={classNames(styles.bar, { [styles.active]: active })}
        style={
          {
            '--line-spacing': `${lineSpacing}px`,
            height: `${lineHeight}px`,
          } as CSSProperties
        }
      />
    </button>
  )
}

export default Burger
