import 'vanilla-cookieconsent/dist/cookieconsent.css'

import { FC, useContext, useEffect } from 'react'
import * as VanillaCookieConsent from 'vanilla-cookieconsent'

import { Locale } from 'ui/contexts/InternationalizationContext'
import { PageContext } from 'ui/contexts/PageContext'
import { COOKIE_CONSENT_TRANSLATIONS } from 'ui/translations'

import './styles.override.css'

declare const window: Window &
  typeof globalThis & { dataLayer: Record<string, unknown>[] }

type UpdateConsentParams = { cookie: VanillaCookieConsent.CookieValue }

enum CookieCategory {
  ANALYTICS = 'analytics',
  MARKETING = 'marketing',
  NECESSARY = 'necessary',
}

const MARKETING_LOCAL_STORAGE_ELEMENTS = [
  'tt_appId',
  'tt_appInfo',
  'tt_pixel_session_index',
  'tt_sessionId',
]

export function showCookiePreferences() {
  VanillaCookieConsent.showPreferences()
}

function emitDataLayerEvent(event: string) {
  const dataLayer = window.dataLayer || []
  dataLayer.push({ event })
}

function updateConsent({ cookie }: UpdateConsentParams) {
  emitDataLayerEvent('consent_update')

  if (cookie.categories.indexOf(CookieCategory.MARKETING) === -1) {
    MARKETING_LOCAL_STORAGE_ELEMENTS.forEach(element =>
      sessionStorage.removeItem(element)
    )
  }
}

const CookieConsent: FC = () => {
  const { locale } = useContext(PageContext)

  useEffect(() => {
    void VanillaCookieConsent.setLanguage(locale)
    void VanillaCookieConsent.run({
      categories: {
        [CookieCategory.ANALYTICS]: {
          autoClear: {
            cookies: [
              {
                name: /^_ga/,
              },
            ],
            reloadPage: false,
          },
        },
        [CookieCategory.MARKETING]: {
          autoClear: {
            cookies: [
              {
                name: 'AEC',
              },
              {
                name: 'ANID',
              },
              {
                name: 'APISID',
              },
              {
                name: 'ar_debug',
              },
              {
                name: 'csrf_token',
              },
              {
                name: 'csv',
              },
              {
                name: 'c_user',
              },
              {
                name: 'datadome',
              },
              {
                name: 'datr',
              },
              {
                name: 'dpr',
              },
              {
                name: 'edgebucket',
              },
              {
                name: '_fbp',
              },
              {
                name: 'fr',
              },
              {
                name: 'HSID',
              },
              {
                name: 'IDE',
              },
              {
                name: 'loid',
              },
              {
                name: 'm_pixel_ratio',
              },
              {
                name: 'msToken',
              },
              {
                name: 'NID',
              },
              {
                name: 'passport_csrf_token',
              },
              {
                name: 'passport_csrf_token_default',
              },
              {
                name: 'presence',
              },
              {
                name: 'ps_l',
              },
              {
                name: 'ps_n',
              },
              {
                name: '_rdt_uuid',
              },
              {
                name: /_recentclicks/,
              },
              {
                name: 'recent_srs',
              },
              {
                name: 'reddit_session',
              },
              {
                name: 'SAPISID',
              },
              {
                name: 'sb',
              },
              {
                name: '__Secure-1PAPISID',
              },
              {
                name: '__Secure-1PSID',
              },
              {
                name: '__Secure-1PSIDCC',
              },
              {
                name: '__Secure-1PSIDTS',
              },
              {
                name: '__Secure-3PAPISID',
              },
              {
                name: '__Secure-3PSID',
              },
              {
                name: '__Secure-3PSIDCC',
              },
              {
                name: '__Secure-3PSIDTS',
              },
              {
                name: 'session_tracker',
              },
              {
                name: 'SID',
              },
              {
                name: 'SIDCC',
              },
              {
                name: 'SSID',
              },
              {
                name: 'theme',
              },
              {
                name: 'token_v2',
              },
              {
                name: 'tta_attr_id_mirror',
              },
              {
                name: '_tt_enable_cookie',
              },
              {
                name: '_ttp',
              },
              {
                name: 'ttwid',
              },
              {
                name: 'USER',
              },
              {
                name: 'usida',
              },
              {
                name: 'wd',
              },
              {
                name: 'x-referer',
              },
              {
                name: 'xs',
              },
              {
                name: '1P_JAR',
              },
            ],
            reloadPage: true,
          },
        },
        [CookieCategory.NECESSARY]: {
          enabled: true,
          readOnly: true,
        },
      },
      disablePageInteraction: true,
      guiOptions: {
        consentModal: {
          layout: 'bar inline',
        },
      },
      language: {
        default: locale,
        translations: {
          [Locale.EN]: COOKIE_CONSENT_TRANSLATIONS.en,
          [Locale.FR]: COOKIE_CONSENT_TRANSLATIONS.fr,
        },
      },
      onChange: updateConsent,
      onFirstConsent: updateConsent,
    })
  }, [locale])

  return null
}

export default CookieConsent
