import React, { createContext, FC, ReactNode } from 'react'

import useState, { Media } from './state'

export const MediaContext = createContext(Media.DESKTOP)

export { Media }

interface Props {
  children: ReactNode
}

const MediaProvider: FC<Props> = ({ children }) => {
  const media = useState()

  return <MediaContext.Provider value={media}>{children}</MediaContext.Provider>
}

export default MediaProvider
