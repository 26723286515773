import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query Contact {
    site {
      siteMetadata {
        emails {
          hiring
          main
        }
      }
    }

    email: file(relativePath: { eq: "email.svg" }) {
      publicURL
    }

    marker: file(relativePath: { eq: "marker.svg" }) {
      publicURL
    }
  }
`

export default () =>
  useStaticQuery<RecursiveNonNullable<Fika.Queries.ContactQuery>>(query)
