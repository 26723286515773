import classNames from 'classnames'
import { type ImageDataLike, GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { forwardRef, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Button, { Transparency } from 'ui/components/Button'
import { LoadingContext } from 'ui/contexts/LoadingContext'
import {
  Div,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from 'ui/utils'

import styles from './styles.module.css'

interface Props {
  buttons?: {
    className?: string
    styles?: { hollow?: boolean; transparency?: Transparency }
    text: string
    to: string
  }[]
  className?: string
  image?: {
    alt: string
    className?: string
    data: ImageDataLike | null
  }
  spacers?: {
    bottom?: string
    top?: string
  }
  text?: {
    className?: string
    tag: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    value: string
  }
}

const Menu = forwardRef<HTMLDivElement, Props>(
  ({ buttons, className, image, spacers, text }, ref) => {
    const [register, notify] = useContext(LoadingContext)
    const { formatMessage } = useIntl()

    const img = getImage(image?.data ?? null)

    let Text

    if (text) {
      switch (text.tag) {
        case 'div':
          Text = Div
          break
        case 'h1':
          Text = Heading1
          break
        case 'h2':
          Text = Heading2
          break
        case 'h3':
          Text = Heading3
          break
        case 'h4':
          Text = Heading4
          break
        case 'h5':
          Text = Heading5
          break
        case 'h6':
          Text = Heading6
          break
        default:
          throw new Error('Unsupported text tag!')
      }
    }

    return (
      <div ref={ref} className={classNames(styles.container, className)}>
        <div className={classNames(styles.topSpacer, spacers?.top)} />

        <div className={styles.imageContainer} data-aos="fade-up">
          {img && (
            <GatsbyImage
              alt={formatMessage({ id: image?.alt })}
              className={classNames(styles.image, image?.className)}
              image={img}
              loading="eager"
              objectFit="contain"
              onLoad={notify}
              onStartLoad={register}
            />
          )}
        </div>

        {text && Text && (
          <Text
            className={classNames(styles.text, text.className)}
            data-aos="fade-up"
          >
            <FormattedMessage id={text.value} />
          </Text>
        )}

        <div className={styles.buttonsContainer} data-aos="fade-up">
          {buttons?.map(({ className, styles, text, to }) => (
            <div key={text}>
              <a
                aria-labelledby={text}
                href={to}
                rel="noreferrer"
                tabIndex={-1}
                target="_blank"
              >
                <Button
                  className={className}
                  hollow={styles && styles.hollow}
                  id={text}
                  transparency={styles && styles.transparency}
                >
                  <FormattedMessage id={text} />
                </Button>
              </a>
            </div>
          )) ?? null}
        </div>

        <div className={classNames(styles.bottomSpacer, spacers?.bottom)} />
      </div>
    )
  }
)

export default Menu
