import React, { FC, ReactNode, useContext } from 'react'
import { IntlProvider } from 'react-intl'

import { useData } from 'ui/contexts/InjectionContext'
import { PageContext } from 'ui/contexts/PageContext'
import TRANSLATIONS from 'ui/translations'

export enum Locale {
  EN = 'en',
  FR = 'fr',
}

interface Props {
  children: ReactNode
  translations: Record<Locale, IntlProvider['props']['messages']>
}

const InternationalizationProvider: FC<Props> = ({
  children,
  translations = { en: {}, fr: {} },
}) => {
  const { locale } = useContext(PageContext)
  const {
    site: {
      siteMetadata: { defaultLocale },
    },
  } = useData()

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      locale={locale || defaultLocale}
      messages={{ ...TRANSLATIONS[locale], ...translations[locale] }}
    >
      {children}
    </IntlProvider>
  )
}

export default InternationalizationProvider
