import { useCallback, useEffect, useRef, useState } from 'react'

export default () => {
  const loadingScreenRef = useRef<HTMLDivElement>(null)
  const registrationCount = useRef(0)

  const [isLoading, setLoadingState] = useState(true)
  const [isTransitioning, setTransitionState] = useState<boolean | undefined>()

  const notify = useCallback(
    () =>
      setTimeout(() => {
        registrationCount.current -= 1

        if (registrationCount.current <= 0) {
          setLoadingState(false)
          setTransitionState(true)
        }
      }),
    []
  )

  const register = useCallback(() => {
    registrationCount.current += 1

    if (registrationCount.current > 0) {
      setLoadingState(true)
    }
  }, [])

  const skip = useCallback(() => setLoadingState(false), [])

  useEffect(() => {
    function endTransition() {
      setTransitionState(false)
    }

    const loadingScreen = loadingScreenRef.current
    loadingScreen?.addEventListener('transitionend', endTransition)

    return loadingScreen?.removeEventListener('transitionend', endTransition)
  }, [])

  return {
    isLoading,
    isTransitioning,
    loadingScreenRef,
    notify,
    register,
    skip,
  }
}
