import classNames from 'classnames'
import React, { ComponentProps, forwardRef, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import ExternalLinks from 'ui/components/ExternalLinks'
import { useData } from 'ui/contexts/InjectionContext'
import { useRemToPx } from 'ui/utils'

import useState from './state'
import styles from './styles.module.css'

export enum Availability {
  NONE,
  AVAILABLE,
}

export type TPlatform = 'Nintendo Switch' | 'PlayStation' | 'Steam' | 'Xbox'

interface Props {
  availability?: Availability
  links: {
    ariaLabel: TPlatform
    to: string
  }[]
}

const Platforms = forwardRef<
  HTMLAnchorElement,
  Omit<ComponentProps<typeof ExternalLinks>, 'links'> & Props
>(({ availability = Availability.NONE, className, links, ...props }, ref) => {
  const { nintendoSwitch, playStation, steam, xbox } = useData()
  const { textContainerRef, textWidth } = useState()

  const nintendoSwitchIcon = nintendoSwitch?.publicURL
  const playStationIcon = playStation?.publicURL
  const steamIcon = steam?.publicURL
  const xboxIcon = xbox?.publicURL

  const mappedLinks = useMemo(
    () =>
      links.map(link => {
        let src

        switch (link.ariaLabel) {
          case 'Nintendo Switch':
            src = nintendoSwitchIcon
            break
          case 'PlayStation':
            src = playStationIcon
            break
          case 'Steam':
            src = steamIcon
            break
          case 'Xbox':
            src = xboxIcon
            break
          default:
            throw new Error('Unsupported platform type!')
        }

        return { ...link, src }
      }),
    [links, nintendoSwitchIcon, playStationIcon, steamIcon, xboxIcon]
  )

  const maxTextWidth = useRemToPx(6.25)

  return availability === Availability.AVAILABLE ? (
    <span className={classNames(styles.container, className)}>
      <div
        ref={textContainerRef}
        className={styles.text}
        style={textWidth > maxTextWidth ? { width: 'min-content' } : undefined}
      >
        <FormattedMessage id="platforms.availability" />
      </div>

      <ExternalLinks ref={ref} links={mappedLinks} {...props} />
    </span>
  ) : (
    <ExternalLinks
      ref={ref}
      className={className}
      links={mappedLinks}
      {...props}
    />
  )
})

export default Platforms
