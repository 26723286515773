import { type TSocialMedia } from '@shared/ui'
import { useMemo } from 'react'

import useData from './query'

export default () => {
  const {
    site: {
      siteMetadata: {
        externalUrls: {
          facebook: facebookUrl,
          github: githubUrl,
          instagram: instagramUrl,
          linkedin: linkedinUrl,
          x: xUrl,
        },
      },
    },
  } = useData()

  const links = useMemo<{ ariaLabel: TSocialMedia; to: string }[]>(
    () => [
      { ariaLabel: 'Facebook', to: facebookUrl },
      { ariaLabel: 'X', to: xUrl },
      { ariaLabel: 'Instagram', to: instagramUrl },
      { ariaLabel: 'LinkedIn', to: linkedinUrl },
      { ariaLabel: 'GitHub', to: githubUrl },
    ],
    [facebookUrl, githubUrl, instagramUrl, linkedinUrl, xUrl]
  )

  return links
}
