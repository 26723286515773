import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { MediaContext } from 'ui/contexts/MediaContext'
import { pxValue } from 'ui/utils'

import styles from './styles.module.css'

export enum State {
  ANCHORED,
  FREE,
  FULLSCREEN,
  FULLSCREEN_CLOSING,
}

const FLOATING_POINT_TOLERANCE = 1.5

function disableScrolling() {
  document.documentElement.style.setProperty('overflow-y', 'hidden')
}

function enableScrolling() {
  document.documentElement.style.removeProperty('overflow-y')
}

function hide(element: HTMLElement | null) {
  if (!element) {
    return
  }

  if (!element.classList.contains(styles.hiddenNavbar)) {
    element.classList.add(styles.hiddenNavbar)
  }
}

function isScrollingDisabled() {
  return (
    document.documentElement.style.getPropertyValue('overflow-y') === 'hidden'
  )
}

function show(element: HTMLElement | null) {
  if (!element) {
    return
  }

  if (element.classList.contains(styles.hiddenNavbar)) {
    element.classList.remove(styles.hiddenNavbar)
  }
}

export default (fillRatio = 1, getElementsWidth?: () => number) => {
  const navbarRef = useRef<HTMLDivElement>(null)

  /* Scrolling */

  const ignoreNext = useRef(false)
  const scrollY = useRef(0)
  const scrollHeight = useRef(0)
  const [state, setState] = useState(State.FREE)

  const scroll = useCallback(() => {
    if (document.documentElement.scrollHeight !== scrollHeight.current) {
      ignoreNext.current = true
    } else if (ignoreNext.current) {
      ignoreNext.current = false
    } else if (window.scrollY < 1) {
      show(navbarRef.current)
      setState(State.ANCHORED)
    } else if (
      window.scrollY < scrollY.current - 1 &&
      document.documentElement.scrollHeight >= scrollHeight.current
    ) {
      show(navbarRef.current)
      setState(State.FREE)
    } else if (window.scrollY >= scrollY.current) {
      hide(navbarRef.current)
    }

    scrollHeight.current = document.documentElement.scrollHeight
    scrollY.current = window.scrollY
  }, [])

  useEffect(() => {
    scrollHeight.current = document.documentElement.scrollHeight
    scrollY.current = window.scrollY
    scroll()
  }, [scroll])

  useEffect(() => {
    window.addEventListener('scroll', scroll)
    return () => window.removeEventListener('scroll', scroll)
  }, [scroll])

  /* Burger mode */

  const media = useContext(MediaContext)
  const [burgerized, setBurgerMode] = useState(false)

  useEffect(() => {
    function updateLayout() {
      const navbar = navbarRef.current
      const hasElements = getElementsWidth != null

      if (!navbar || (hasElements && getElementsWidth() === 0)) {
        return
      }

      const navbarWidth = navbar.offsetWidth
      const sideMarginsWidth =
        pxValue(getComputedStyle(navbar).paddingLeft) +
        pxValue(getComputedStyle(navbar).paddingRight)
      const contentWidth = (navbarWidth - sideMarginsWidth) * fillRatio
      const requiredWidth =
        (getElementsWidth?.() ?? 0) + FLOATING_POINT_TOLERANCE

      setBurgerMode(contentWidth < requiredWidth)
    }

    updateLayout()
    window.addEventListener('resize', updateLayout)

    return () => window.removeEventListener('resize', updateLayout)
  }, [fillRatio, getElementsWidth, media])

  /* Fullscreen mode */

  const [fullscreen, setFullscreen] = useState<HTMLDivElement>()

  const fullscreenRef = useCallback(
    (element: HTMLDivElement) => setFullscreen(element),
    []
  )

  const toggleFullscreen = useCallback(() => {
    if (state !== State.FULLSCREEN) {
      disableScrolling()
      setState(State.FULLSCREEN)
    } else {
      setState(State.FULLSCREEN_CLOSING)
    }
  }, [state])

  /* Burger mode - Fullscreen mode interactions */

  useEffect(() => {
    if (!burgerized) {
      enableScrolling()
      scroll()

      if (state !== State.ANCHORED && state !== State.FREE) {
        setState(State.FREE)
      }
    }
  }, [scroll, state, burgerized])

  /* Scrolling - Fullscreen mode interactions */

  useEffect(() => {
    function reenableScrolling(this: HTMLDivElement) {
      if (isScrollingDisabled() && getComputedStyle(this).height === '0px') {
        enableScrolling()

        if (window.scrollY < 1) {
          setState(State.ANCHORED)
        } else {
          setState(State.FREE)
        }
      }
    }

    fullscreen?.addEventListener('transitionend', reenableScrolling)
    return () =>
      fullscreen?.removeEventListener('transitionend', reenableScrolling)
  }, [fullscreen])

  return {
    burgerized,
    fullscreenRef,
    navbarRef,
    state,
    toggleFullscreen,
  }
}
