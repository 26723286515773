import classNames from 'classnames'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useSwiper } from 'swiper/react'

import Fab from 'ui/components/Fab'
import { useData } from 'ui/contexts/InjectionContext'

import styles from './styles.module.css'

interface Props {
  className?: string
}

const Navigation: FC<Props> = ({ className }) => {
  const { formatMessage } = useIntl()
  const {
    carouselNavigationIcon: { publicURL: carouselNavigationIcon },
  } = useData()

  const swiper = useSwiper()

  return (
    <div className={classNames(styles.container, className)}>
      <Fab
        ariaLabel={formatMessage({ id: 'carousel.navigation.previous' })}
        className={styles.flipped}
        src={carouselNavigationIcon}
        onClick={() => swiper.slidePrev()}
      />

      <Fab
        ariaLabel={formatMessage({ id: 'carousel.navigation.next' })}
        src={carouselNavigationIcon}
        onClick={() => swiper.slideNext()}
      />
    </div>
  )
}

export default Navigation
