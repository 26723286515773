import type { Aos } from 'aos'
import { ImageDataLike } from 'gatsby-plugin-image'
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import { Locale } from 'ui/contexts/InternationalizationContext'

const InjectionContext = createContext<[Data, OptionalDependencies]>([
  {
    carouselNavigationIcon: { publicURL: '' },
    collapsibleSectionIcon: { publicURL: '' },
    discord: { publicURL: '' },
    facebook: { publicURL: '' },
    flourish: { publicURL: '' },
    github: { publicURL: '' },
    instagram: { publicURL: '' },
    kickstarterEndingSoonBannerEn: null,
    kickstarterEndingSoonBannerFr: null,
    kickstarterJustFundedBannerEn: null,
    kickstarterJustFundedBannerFr: null,
    kickstarterJustLaunchedBannerEn: null,
    kickstarterJustLaunchedBannerFr: null,
    kickstarterLatePledgeBannerEn: null,
    kickstarterLatePledgeBannerFr: null,
    kickstarterNearlyFundedBannerEn: null,
    kickstarterNearlyFundedBannerFr: null,
    linkedin: { publicURL: '' },
    nintendoSwitch: { publicURL: '' },
    ogImage: {
      childImageSharp: {
        original: { height: 0, width: 0 },
      },
      publicURL: '',
    },
    playStation: { publicURL: '' },
    site: {
      siteMetadata: {
        baseUrl: '',
        copyright: '',
        defaultLocale: Locale.EN,
        descriptions: { en: '' },
        keywords: { en: '' },
        locales: [Locale.EN],
        titles: { en: '' },
      },
    },
    steam: { publicURL: '' },
    x: { publicURL: '' },
    xbox: { publicURL: '' },
  },
  {
    aos: { current: null },
  },
])

export function useData(): Data {
  return useContext(InjectionContext)[0]
}

export function useOptionalDependency(name: keyof OptionalDependencies) {
  return useContext(InjectionContext)[1][name].current
}

interface Data {
  carouselNavigationIcon: { publicURL: string }
  collapsibleSectionIcon: { publicURL: string }
  discord: { publicURL: string }
  facebook: { publicURL: string }
  flourish: { publicURL: string }
  github: { publicURL: string }
  instagram: { publicURL: string }
  kickstarterEndingSoonBannerEn: ImageDataLike | null
  kickstarterEndingSoonBannerFr: ImageDataLike | null
  kickstarterJustFundedBannerEn: ImageDataLike | null
  kickstarterJustFundedBannerFr: ImageDataLike | null
  kickstarterJustLaunchedBannerEn: ImageDataLike | null
  kickstarterJustLaunchedBannerFr: ImageDataLike | null
  kickstarterLatePledgeBannerEn: ImageDataLike | null
  kickstarterLatePledgeBannerFr: ImageDataLike | null
  kickstarterNearlyFundedBannerEn: ImageDataLike | null
  kickstarterNearlyFundedBannerFr: ImageDataLike | null
  linkedin: { publicURL: string }
  nintendoSwitch: { publicURL: string }
  ogImage: {
    childImageSharp: {
      original: { height: number; width: number }
    }
    publicURL: string
  }
  playStation: { publicURL: string }
  site: {
    siteMetadata: {
      baseUrl: string
      copyright: string
      defaultLocale: string
      descriptions: { [key: string]: string }
      keywords: { [key: string]: string }
      locales: readonly string[]
      titles: { [key: string]: string }
    }
  }
  steam: { publicURL: string }
  x: { publicURL: string }
  xbox: { publicURL: string }
}

interface OptionalDependencies {
  aos: React.MutableRefObject<Aos | null>
}

interface Props {
  children: ReactNode
  data: Data
}

const InjectionProvider: FC<Props> = ({ children, data }) => {
  const aosRef = useRef<Aos | null>(null)

  useEffect(() => {
    async function tryLoad() {
      try {
        aosRef.current = (await import('aos')) as Aos
      } catch (ex) {
        aosRef.current = null
      }
    }

    void tryLoad()
  }, [])

  return (
    <InjectionContext.Provider
      value={useMemo(() => [data, { aos: aosRef }], [data])}
    >
      {children}
    </InjectionContext.Provider>
  )
}

export default InjectionProvider
