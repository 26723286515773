import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import styles from './styles.module.css'

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
  TRAILER = 'trailer',
}

interface Props {
  after?: () => ReactNode
  alpha?: boolean
  backgroundClassName?: string
  before?: () => ReactNode
  children: ReactNode
  className?: string
  short?: boolean
  theme: Theme
}

const Section: FC<Props> = ({
  after,
  alpha = false,
  backgroundClassName,
  before,
  children,
  className,
  short,
  theme,
}) => (
  <div
    className={classNames(
      styles.background,
      `fika-${theme}-theme${alpha ? '-with-alpha' : ''}`,
      backgroundClassName
    )}
  >
    {before?.()}
    <div
      className={classNames(
        styles.section,
        { [styles.short]: short },
        className
      )}
    >
      {children}
    </div>
    {after?.()}
  </div>
)

export default Section
