import classNames from 'classnames'
import React, { FC, ReactNode, useContext, useEffect, useRef } from 'react'

import { LoadingContext } from 'ui/contexts/LoadingContext'

import styles from './styles.module.css'

interface Props {
  children?: ReactNode
  className?: string
  loop?: boolean
  src: string
}

const FullscreenVideo: FC<Props> = ({
  children,
  className,
  loop = false,
  src,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [register, notify] = useContext(LoadingContext)

  useEffect(() => {
    register()

    if ((videoRef.current?.readyState ?? 0) >= 2 /* HAVE_CURRENT_DATA */) {
      notify()
    }
  }, [register, notify])

  return (
    <div className={styles.fullscreen}>
      <video
        ref={videoRef}
        autoPlay
        className={classNames(styles.video, className)}
        loop={loop}
        muted
        playsInline
        onLoadedData={notify}
      >
        <source src={src} type="video/mp4" />
      </video>

      {children != null ? (
        <div className={styles.container}>{children}</div>
      ) : null}
    </div>
  )
}

export default FullscreenVideo
