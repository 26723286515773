import CookieConsent_en from './CookieConsent/en.json'
import CookieConsent_fr from './CookieConsent/fr.json'
import en from './en.json'
import fr from './fr.json'

export const COOKIE_CONSENT_TRANSLATIONS = {
  en: CookieConsent_en,
  fr: CookieConsent_fr,
}

export default {
  en,
  fr,
}
