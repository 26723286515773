import { RootLayout as SharedRootLayout } from '@shared/ui'
import React, { FC, ReactNode } from 'react'

import useSharedData from './query.shared.ui'

interface Props {
  children: ReactNode
}

const RootLayout: FC<Props> = ({ children }) => (
  <SharedRootLayout data={useSharedData()}>{children}</SharedRootLayout>
)

export default RootLayout
