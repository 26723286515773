import React, {
  AnchorHTMLAttributes,
  forwardRef,
  Fragment,
  HTMLAttributes,
  ReactNode,
  useContext,
} from 'react'

import { MediaContext } from 'ui/contexts/MediaContext'
import designTokens from 'ui/design-tokens.json'

export function getFontComputedStyle(
  elt: Element,
  pseudoElt?: string | null | undefined
) {
  const style = getComputedStyle(elt, pseudoElt)

  if (style.font) return style.font

  const { fontStyle, fontVariant, fontWeight, fontSize, fontFamily } = style
  return `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize} ${fontFamily}`
    .replace(/ +/g, ' ')
    .trim()
}

export function percentToFloat(percent: string) {
  if (!percent.endsWith('%')) {
    return 0
  }

  return parseFloat(percent.slice(0, -1)) / 100
}

export function pxValue(px: string) {
  if (!px.endsWith('px')) {
    return 0
  }

  return parseFloat(px.slice(0, -2))
}

export function remValue(rem: string) {
  if (!rem.endsWith('rem')) {
    return 0
  }

  return parseFloat(rem.slice(0, -3))
}

export function sanitizeText(
  { lines = false, spaces = false } = {
    lines: false as boolean,
    spaces: false as boolean,
  }
) {
  return ([node]: ReactNode[]) => {
    const text = node as string
    return (
      <>
        {(lines ? text.split('\n') : [text]).map(
          (sentence, index, sentences) => (
            <Fragment key={sentence}>
              {(spaces ? sentence.split(' ') : [sentence]).flatMap(
                (word, index, words) =>
                  index < words.length - 1
                    ? [word, <Fragment key={word}>&nbsp;</Fragment>]
                    : word
              )}

              {index < sentences.length - 1 ? <br /> : null}
            </Fragment>
          )
        )}
      </>
    )
  }
}

export function useRemToPx(rem: number | string) {
  const media = useContext(MediaContext)
  const root = designTokens['font-size'].root[media].value

  return (typeof rem === 'string' ? remValue(rem) : rem) * pxValue(root)
}

/* HTML tags */

export const Anchor = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a ref={ref} {...props} />
))

export const Div = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => <div ref={ref} {...props} />
)

export const Heading1 = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h1 ref={ref} {...props} />
))

export const Heading2 = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h2 ref={ref} {...props} />
))

export const Heading3 = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3 ref={ref} {...props} />
))

export const Heading4 = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h4 ref={ref} {...props} />
))

export const Heading5 = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h5 ref={ref} {...props} />
))

export const Heading6 = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h6 ref={ref} {...props} />
))
